import { Controller } from "stimulus"
import * as Credential from "credential";

export default class extends Controller {
  create(event) {
    var [data, status, xhr] = event.detail;
    console.log(data);
    var credentialOptions = data;
    Credential.get(credentialOptions);
  }

  error(event) {
    let response = event.detail[0];
    usernameField.valid = false;
    usernameField.helperTextContent = response["errors"][0];
  }
}
